import React, { useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Market from "../pages/market/Market";
import Explore from "../pages/explore/Explore";
import Faq from "../pages/others/Faq";
import Regularv1 from "../pages/others/Regular-1";
import Regularv2 from "../pages/others/Regular-2";
import Terms from "../pages/others/Terms";

import Chat from "../pages/app/chat/ChatContainer";

import TraidHub from "../pages/app/traid-hub/TraidHub";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";

import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Success from "../pages/auth/Success";

import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import LayoutNoSidebarNoScroll from "../layout/Index-nosidebar-no-scroll";
import OnePager from "../pages/one-pager/OnePager";
import Watchlists from "../pages/watchlists/Watchlists";
import SocialAnalysis from "../pages/social-analysis/SocialAnalysis";
import StrategiesPage from "../pages/strategies/StrategiesPage";
import Authmiddleware from "./authMiddleware";
import BacktestPage from "../pages/strategies/BacktestPage";
// import StrategyBacktests from "../pages/strategies/StrategyBacktests";
import StrategyPage from "../pages/strategies/StrategyPage";

import IndexFive from "../landingApp/pages/IndexFive";
import Support from "../components/support/Support";
import SupportTopics from "../components/support/SupportTopics";
import SupportTopicDetails from "../components/support/SupportTopicDetails";
import StripeWrapper from "../stripe";
import UserDetails from "../pages/pre-built/user-manage/UserDetailsRegular";
import { UserContextProvider } from "../pages/pre-built/user-manage/UserContext";
import News from "../pages/news/News";
import Screener from "../pages/screener/Screener";
import MDFile from "../pages/education/MDFile";
import Blog from "../pages/blog/Blog";
import { useTheme } from "../layout/provider/Theme";
import DeepChat from "../pages/open-chat/DeepChat";
import Portfolios from "../pages/portfolios/Portfolios";
import PortfolioContainer from "../pages/portfolios/PortfolioContainer";
import TradingBot from "../pages/bots/trading-bot-new/TradingBot";
import { MyProvider } from "../pages/bots/trading-bot-new/ReactFlowBuilder/store";
import TourWrapper from "../pages/bots/trading-bot-new/TourWrapper";
import BotsPage from "../pages/bots/BotsPage";
import BotPage from "../pages/bots/BotPage";
import CheckLoginUser from "./CheckLoginUser";
import Home from "../pages/home/Home";

const Router = () => {
  const theme = useTheme();
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  var appleThemeColor = document.querySelector("meta[name=theme-color]");
  // var appleThemeColor = document.querySelector("meta[name=apple-mobile-web-app-status-bar-style]");
  if (theme?.skin === "dark") appleThemeColor.setAttribute("content", "#101924");
  else appleThemeColor.setAttribute("content", "#FFF");

  return (
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}`} element={
        <Authmiddleware>
          <Layout />
        </Authmiddleware>
      }>
        {/* <Route path="news" element={<News />}></Route> */}
        {/* <Route path="support" element={<Support />}></Route> */}
        <Route path="portfolios" element={<Portfolios />}></Route>
        <Route path="portfolios/:id" element={<PortfolioContainer />}></Route>
        {/* <Route path="social-analysis" element={<SocialAnalysis />}></Route> */}
        {/*<Route path="strategies" element={<StrategiesPage />}></Route> */}
        <Route path="screener" element={<Screener />}></Route>
        <Route path="market" element={<Market />}></Route>
        <Route path="home" element={<Home />}></Route>
        <Route path="explore" element={<Explore />}></Route>
        <Route path="watchlists" element={<CheckLoginUser><Watchlists /></CheckLoginUser>}></Route>
        <Route path="bots" element={<CheckLoginUser><BotsPage /></CheckLoginUser>}></Route>
        <Route path="one-pager/:symbol" element={<OnePager />}></Route>
        <Route path="strategy/:id" element={<StrategyPage />}></Route>
        {/* <Route path="strategy/:id/backtests" element={<StrategyBacktests />}></Route> */}
        <Route path="strategy/:strategyId/backtest/:backtestId" element={<BacktestPage />}></Route>
        <Route path="plans" element={<StripeWrapper />}></Route>
        <Route path="blog/technical-analysis" element={<MDFile MDFilePath={"technicalAnalysis.md"} />}></Route>
        <Route path="blog/support-and-resistance" element={<MDFile MDFilePath={"supportAndResistance.md"} />}></Route>
        <Route path="blog/chart-basics" element={<MDFile MDFilePath={"chartsBasics.md"} />}></Route>
        <Route path="blog/time-frames" element={<MDFile MDFilePath={"timeFrames.md"} />}></Route>
        <Route path="blog/trends" element={<MDFile MDFilePath={"trends.md"} />}></Route>
        <Route path="blog/dow-theory" element={<MDFile MDFilePath={"dowTheory.md"} />}></Route>
        <Route path="blog/warren-buffett-indicator" element={<MDFile MDFilePath={"warrenBuffettIndicator.md"} />}></Route>
        <Route path="blog/indicators/adx" element={<MDFile MDFilePath={"indicators/adx.md"} />}></Route>
        <Route path="blog/indicators/ma" element={<MDFile MDFilePath={"indicators/ma.md"} />}></Route>
        <Route path="blog/indicators/ema" element={<MDFile MDFilePath={"indicators/ema.md"} />}></Route>
        <Route path="blog/indicators/macd" element={<MDFile MDFilePath={"indicators/macd.md"} />}></Route>
        <Route path="blog/indicators/obv" element={<MDFile MDFilePath={"indicators/obv.md"} />}></Route>
        <Route path="blog/indicators/dmi" element={<MDFile MDFilePath={"indicators/dmi.md"} />}></Route>
        <Route path="blog/indicators/ichimoko-cloud" element={<MDFile MDFilePath={"indicators/ichimokoCloud.md"} />}></Route>
        <Route path="blog/indicators/aroon-oscillator" element={<MDFile MDFilePath={"indicators/aroonOscillator.md"} />}></Route>
        <Route path="blog/patterns/head-and-shoulders" element={<MDFile MDFilePath={"patterns/headAndShoulders.md"} />}></Route>
        <Route path="blog/patterns/cup-and-handle" element={<MDFile MDFilePath={"patterns/cupAndHandle.md"} />}></Route>
        <Route path="blog/patterns/triangle-and-wedge" element={<MDFile MDFilePath={"patterns/triangleAndWedge.md"} />}></Route>
        <Route path="blog" element={<Blog />}></Route>

        <Route path="bot/:id" element={
          <TourWrapper><BotPage /></TourWrapper>
        }></Route>

        {/* <Route path={`indextwo`} element={<IndexTwo />} /> */}
        {/* <Route path="crypto" element={<Crypto />}></Route>
          <Route path="analytics" element={<Analytics />}></Route>
          <Route path="invest" element={<Invest />}></Route>
          <Route path="_blank" element={<Blank />}></Route> */}


        <Route path="support/:topicSlug" element={<SupportTopics />}></Route>
        <Route path="support/:topicSlug/:questionId" element={<SupportTopicDetails />}></Route>

        {/* <Route path="project-card" element={<ProjectCardPage />}></Route>
          <Route path="project-list" element={<ProjectListPage />}></Route> */}


        {/*<Route element={<UserContextProvider />} >
             <Route path="user-list-regular" element={<UserListRegular />}></Route>
            <Route path="user-list-compact" element={<UserListCompact />}></Route>
            <Route path="user-contact-card" element={<UserContactCard />}></Route>
          </Route> */}

        <Route >
          <Route path="profile" element={<UserDetails />}></Route>
          {/* <Route path="user-profile-notification" element={<UserProfileNotificationPage />} ></Route>
            <Route path="user-profile-regular" element={<UserProfileRegularPage />}></Route>
            <Route path="user-profile-activity" element={<UserProfileActivityPage />}></Route>
            <Route path="user-profile-setting" element={<UserProfileSettingPage />}></Route> */}
        </Route>

        {/* <Route path="kyc-list-regular" element={<KycListRegular />}></Route>
          <Route path="kyc-details-regular/:kycId" element={<KycDetailsRegular />}></Route>
          <Route path="transaction-basic" element={<TransListBasic />}></Route>
          <Route path="transaction-crypto" element={<TransListCrypto />}></Route>
          <Route element={<ProductContextProvider />}>
            <Route path="product-list" element={<ProductList />}></Route>
            <Route path="product-card" element={<ProductCard />}></Route>
            <Route path="product-details/:productId" element={<ProductDetails />}></Route>
          </Route>

          <Route path="invoice-list" element={<InvoiceList />}></Route>
          <Route path="invoice-details/:invoiceId" element={<InvoiceDetails />}></Route>
          <Route path="pricing-table" element={<PricingTable />}></Route>
          <Route path="image-gallery" element={<GalleryPreview />}></Route> */}

        <Route path="pages">
          <Route path="terms-policy" element={<Terms />}></Route>
          <Route path="faq" element={<Faq />}></Route>
          <Route path="regular-v1" element={<Regularv1 />}></Route>
          <Route path="regular-v2" element={<Regularv2 />}></Route>
        </Route>

        {/* <Route path="app-messages" element={<AppMessages />}></Route> */}
        <Route path="ninja-chat" element={<Chat />}></Route>
        {/* <Route path="app-calender" element={<Calender />}></Route>
          <Route path="app-inbox" element={<Inbox />}></Route>
          <Route path="app-kanban" element={<Kanban />}></Route> */}

        <Route path="traid-hub">
          <Route index element={<TraidHub />}></Route>
          {/* <Route path="files" element={<FileManagerFiles />}></Route>
            <Route path="starred" element={<FileManagerStarred />}></Route>
            <Route path="shared" element={<FileManagerShared />}></Route>
            <Route path="recovery" element={<FileManagerRecovery />}></Route>
            <Route path="settings" element={<FileManagerSettings />}></Route> */}
        </Route>

        {/* <Route path="components">
            <Route index element={<Component />}></Route>
            <Route path="accordions" element={<Accordian />}></Route>
            <Route path="alerts" element={<Alerts />}></Route>
            <Route path="avatar" element={<Avatar />}></Route>
            <Route path="badges" element={<Badges />}></Route>
            <Route path="breadcrumbs" element={<Breadcrumbs />}></Route>
            <Route path="button-group" element={<ButtonGroup />}></Route>
            <Route path="buttons" element={<Buttons />}></Route>
            <Route path="cards" element={<Cards />}></Route>
            <Route path="carousel" element={<Carousel />}></Route>
            <Route path="dropdowns" element={<Dropdowns />}></Route>
            <Route path="form-elements" element={<FormElements />}></Route>
            <Route path="form-layouts" element={<FormLayouts />}></Route>
            <Route path="checkbox-radio" element={<CheckboxRadio />}></Route>
            <Route path="advanced-control" element={<AdvancedControls />}></Route>
            <Route path="input-group" element={<InputGroup />}></Route>
            <Route path="form-upload" element={<FormUpload />}></Route>
            <Route path="number-spinner" element={<NumberSpinner />}></Route>
            <Route path="form-validation" element={<FormValidation />}></Route>
            <Route path="datetime-picker" element={<DateTimePicker />}></Route>
            <Route path="modals" element={<Modals />}></Route>
            <Route path="pagination" element={<Pagination />}></Route>
            <Route path="popovers" element={<Popovers />}></Route>
            <Route path="progress" element={<Progress />}></Route>
            <Route path="spinner" element={<Spinner />}></Route>
            <Route path="tabs" element={<Tabs />}></Route>
            <Route path="toast" element={<Toast />}></Route>
            <Route path="tooltips" element={<Tooltips />}></Route>
            <Route path="typography" element={<Typography />}></Route>
            <Route path="noUislider" element={<NouiSlider />}></Route>
            <Route path="wizard-basic" element={<WizardForm />}></Route>
            <Route path="quill" element={<QuillPreview />}></Route>
            <Route path="tinymce" element={<TinymcePreview />}></Route>
            <Route path="util-border" element={<UtilBorder />}></Route>
            <Route path="util-colors" element={<UtilColors />}></Route>
            <Route path="util-display" element={<UtilDisplay />}></Route>
            <Route path="util-embeded" element={<UtilEmbeded />}></Route>
            <Route path="util-flex" element={<UtilFlex />}></Route>
            <Route path="util-others" element={<UtilOthers />}></Route>
            <Route path="util-sizing" element={<UtilSizing />}></Route>
            <Route path="util-spacing" element={<UtilSpacing />}></Route>
            <Route path="util-text" element={<UtilText />}></Route>

            <Route path="widgets">
              <Route path="cards" element={<CardWidgets />}></Route>
              <Route path="charts" element={<ChartWidgets />}></Route>
              <Route path="rating" element={<RatingWidgets />}></Route>
            </Route>

            <Route path="misc">
              <Route path="slick-slider" element={<SlickPage />}></Route>
              <Route path="sweet-alert" element={<SweetAlertPage />}></Route>
              <Route path="beautiful-dnd" element={<BeautifulDnd />}></Route>
              <Route path="dual-list" element={<DualListPage />}></Route>
              <Route path="map" element={<GoogleMapPage />}></Route>
              <Route path="toastify" element={<ReactToastify />}></Route>
              <Route path="jsTree" element={<JsTreePreview />}></Route>
            </Route>
          </Route>
          <Route path="charts">
            <Route path="chartjs" element={<ChartPage />}></Route>
            <Route path="knobs" element={<KnobPreview />}></Route>
          </Route>
          
          <Route path="table-basic" element={<BasicTable />}></Route>
          <Route path="table-datatable" element={<DataTablePage />}></Route>
          <Route path="table-special" element={<SpecialTablePage />}></Route>
          <Route path="email-template" element={<EmailTemplate />}></Route>
          <Route path="nioicon" element={<NioIconPage />}></Route>
          <Route path="svg-icons" element={<SVGIconPage />}></Route> */}

      </Route>
      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route index element={
          // <Authmiddleware>
          <IndexFive />
          // </Authmiddleware>

        }></Route>
        <Route path="auth-success" element={<Success />}></Route>
        <Route path="auth-reset" element={<ForgotPassword />}></Route>
        <Route path="auth-register" element={<Register />}></Route>
        <Route path="login" element={
          // <Authmiddleware>
          <Login />
          // </Authmiddleware>
        }></Route>

        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="traid-chat" element={
          <DeepChat />
        }></Route>

      </Route>
      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebarNoScroll />}>
        <Route path="trading-bot" element={
          <TradingBot />
        }></Route>
      </Route>
      <Route path="*" element={<Error404Modern />}></Route>
    </Routes>
  );
};
export default Router;
