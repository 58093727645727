import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Form,
  Label,
  Modal,
  ModalBody
} from "reactstrap";
import { CREATE_PORTFOLIO_ORDER, GET_PORTFOLIO_BY_ID } from "../../api/graphql";
import AssetLogo from "../../components/AssetLogo";
import { Block, BlockHead, BlockTitle, Icon, TooltipComponent } from "../../components/Component";
import Autocomplete from "../../components/autocomplete/Autocomplete";
import strings from "../../utils/Strings";
import { actionOptions } from "../strategies/StrategyPolicyBuilder/Action";
import { sizeOptions } from "../strategies/StrategyPolicyBuilder/RuleBuilder/Nodes/OrderNode";
import Size from "../strategies/StrategyPolicyBuilder/Size";

const AssetPriceItem = ({ i, tick, quote, onClick }) => {
  const last = parseFloat(tick?.trade?.last) || quote?.last;
  const prevClose = quote?.prevclose;
  const change = last - prevClose;
  const changePct = (change / prevClose) * 100;
  return (
    <div className="analytic-data analytic-wp-data" onClick={onClick} style={{
      cursor: 'pointer'
    }}>
      <div className="analytic-wp-graph" style={{
      }}>
        <div className="title" >
          {i}
        </div>
        <div className="analytic-wp-ck">
          <AssetLogo style={{}} symbol={i} />
        </div>
      </div>
      <div className="analytic-wp-text">
        <div className="amount amount-sm">{strings.priceDecimal(last, 2)}</div>
        <div className={changePct > 0 ? "change up" : changePct < 0 ? "change down" : "change"}>
          <Icon name={changePct > 0 ? "arrow-long-up" : changePct < 0 ? "arrow-long-down" : "change"}></Icon>{strings.percentSignFormatter(changePct)}
        </div>
        <div className="subtitle"></div>
      </div>
    </div>
  )
}

const BuySell = ({ setShowBuySellModal, quotes, asset, setAsset, portfolioData, portfolioId, allMessages }) => {
  const [actionType, setActionType] = useState(actionOptions[0]);
  const [sizeType, setSizeType] = useState({ label: "Shares", value: "shares" })
  const [sizeValue, setSizeValue] = useState(5)
  const [isLoading, setIsLoading] = useState(false)
  const [orderDate, setOrderDate] = useState(new Date());

  const navigate = useNavigate();

  const [createPortfolioOrderMutation] = useMutation(CREATE_PORTFOLIO_ORDER);
  const errorToast = () => {
    toast.error("Insufficient funds 💰", {
      position: "bottom-right",
      autoClose: true,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      closeButton: (<span className="btn-trigger toast-close-button" role="button">
        <Icon name="cross"></Icon>
      </span>),
    });
  };
  // function to close the form modal
  const onFormCancel = () => {
    setModal({ confirm: false, buyCoin: false });
  };
  const [modal, setModal] = useState({
    confirm: false,
    buyCoin: false,
  });

  const onClickApproveOrder = async () => {
    setIsLoading(true);
    const q = Array.isArray(quotes?.quotes?.quote) ? quotes?.quotes?.quote?.filter(q => q.symbol === asset)[0] : quotes?.quotes?.quote
    const last = parseFloat(allMessages?.[asset]?.trade?.last) || q?.last;

    const submittedData = {
      portfolioId,
      actionType,
      asset,
      sizeType,
      sizeValue: actionType?.value === "long" ? sizeValue : sizeValue,
      orderDate,
      price: last
    }
    const res = await createPortfolioOrderMutation({
      variables: {
        input: submittedData
      },
      refetchQueries: [
        {
          query: GET_PORTFOLIO_BY_ID,
          variables: {
            filter: { id: portfolioId }
          }
        }
      ]
    },
    );
    setIsLoading(false);
    setShowBuySellModal && setShowBuySellModal(false);
    setModal({ confirm: true });

  }

  const q = Array.isArray(quotes?.quotes?.quote) ? quotes?.quotes?.quote?.filter(q => q.symbol === asset)[0] : quotes?.quotes?.quote
  const last = parseFloat(allMessages?.[asset]?.trade?.last) || q?.last;
  const requiredFunds = actionType.value === "long" ? last * sizeValue : last * sizeValue * -1;

  return (
    <Card>
      <div className="buysell wide-xs m-auto">
        <ul className="nk-nav nav nav-tabs nav-tabs-s2">
          <li className="nav-item">
            <a className={`nav-link ${actionType.value === "long" ? "active" : ""}`} style={{
              cursor: "pointer"
            }} onClick={() => setActionType(actionOptions[0])}>
              Buy
            </a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${actionType.value === "short" ? "active" : ""}`} style={{
              cursor: "pointer"
            }} onClick={() => setActionType(actionOptions[1])}>
              Sell
            </a>
          </li>
        </ul>
        <CardBody>
          <div className="buysell-block">
            <Form className="buysell-form">
              <div className="form-group buysell-field">
                <div className="form-label-group">
                  <Label for="buysell-choose-currency" className="form-label">
                    <b>Buying power</b>
                  </Label>
                </div>
                {strings.priceDecimal(portfolioData?.funds)}
              </div>
              <div className="form-group buysell-field">
                <div className="form-label-group">
                  <Label for="buysell-choose-currency" className="form-label">
                    <b>Asset</b>
                  </Label>
                </div>
                <Autocomplete
                  defaultValue={asset}
                  onClickOption={(e, symbol) => {
                    e.preventDefault();
                    setAsset(symbol);
                  }} />
              </div>
              <div style={{
                margin: "16px 0px"
              }}>
                {

                  <AssetPriceItem onClick={(() => {
                    navigate(`/one-pager/${asset}`)
                  })} i={asset} tick={allMessages[asset]} quote={q} />

                }
                {/* <div className="custom-control custom-control-sm custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="limitPrice" checked={limitPrice} onChange={(e) => {
                                setLimitPrice(!limitPrice)
                            }} />
                            <label className="custom-control-label form-label" htmlFor="limitPrice">
                                Limit price
                            </label>
                        </div>

              {limitPrice && (
              <>
              <NSComponent min={0} step={1} defaultVal={limitPriceValue || parseFloat(q?.last)} onChange={setLimitPriceValue} />
              </>
              )} */}
              </div>


              <div className="form-group">
                <label className="form-label" htmlFor="email-address">
                  <b>Allocation</b>
                </label>
                <div style={{
                  float: "right",
                  cursor: "default",
                }}
                >
                  <TooltipComponent
                    icon="help-fill"
                    iconClass="card-hint"
                    direction="right"
                    id="allocation-tooltip"
                    text="Set the amount of shares to buy or sell"
                  />
                </div>
                <div className="form-control-wrap">
                  <Size
                    sizeOptions={sizeOptions}
                    sizeType={sizeType}
                    setSizeType={setSizeType}
                    sizeValue={sizeValue} setSizeValue={(v) => setSizeValue(parseFloat(v))} />
                </div>
              </div>
              <div className="form-group">
                <Label><b>Required funds</b></Label>
                <div className="form-control-wrap">
                  {strings.priceDecimal(requiredFunds)}
                </div>
              </div>
              {/* <div className="form-group">
                  <Label><b>Order date</b></Label>
                  <div className="form-control-wrap">
                    <DatePicker selected={orderDate} 
                    onChange={setOrderDate} 
                    maxDate={new Date()}
                    className="form-control date-picker" />{" "}
                  </div>
                </div> */}
              <div className="buysell-field form-action">
                <Button
                  color={actionType.value === "long" ? "success" : "danger"}
                  className="btn btn-lg btn-block btn-primary"
                  onClick={() => {
                    if (portfolioData?.funds < requiredFunds) {
                      console.log("error")
                      errorToast();
                    }
                    else {
                      setModal({ buyCoin: true })
                    }
                  }}
                >
                  {actionType.value === "long" ? "Buy" : "Sell"}
                </Button>
              </div>
            </Form>
          </div>
        </CardBody>
      </div>
      <Modal
        isOpen={modal.buyCoin}
        toggle={() => setModal({ buyCoin: false })}
        className="modal-dialog-centered"
        size="md"
      >
        <a
          href="#close"
          onClick={(ev) => {
            ev.preventDefault();
            onFormCancel();
          }}
          className="close"
        >
          <Icon name="cross-sm" />
        </a>
        <ModalBody className="modal-body-lg">
          <BlockHead className="nk-block-head-xs text-center">
            <BlockTitle tag="h5">Confirm Order</BlockTitle>
            <div className="nk-block-text">
              <div className="caption-text">
                You are about to {actionType?.label} <strong>{sizeValue}</strong> {asset} shares @ <strong>~{strings.priceDecimal(sizeValue * last)}</strong>
              </div>
              {/* <span className="sub-text-sm">Exchange rate: 1 BTC = 9,804.00 USD</span> */}
            </div>
          </BlockHead>
          <Block>
            {/* <div className="buysell-overview">
              <ul className="buysell-overview-list">
                <li className="buysell-overview-item">
                  <span className="pm-title">Pay with</span>
                  <span className="pm-currency">
                    <Icon name="paypal-alt" /> <span>PayPal</span>
                  </span>
                </li>
                <li className="buysell-overview-item">
                  <span className="pm-title">Total</span>
                  <span className="pm-currency">500.00 USD</span>
                </li>
              </ul>
              <div className="sub-text-sm">
                * Our transaction fee are included.{" "}
                <Link
                  tag="a"
                  to="#"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  See transaction fee
                </Link>
              </div>
            </div>
            <Form className="buysell-field">
              <div className="form-group buysell-field">
                <div className="form-label-group">
                  <Label for="buysell-choose-currency" className="form-label">
                    Choose what you want to get
                  </Label>
                  <Link tag="a" to="#" className="link">
                    Add Wallet
                  </Link>
                </div>
                <Input type="hidden" value="btc" name="bs-currency" id="buysell-choose-currency" />
                <UncontrolledDropdown className="buysell-cc-dropdown">
                  <DropdownToggle
                    className="buysell-cc-choosen dropdown-indicator"
                    tag="a"
                    href="toggle"
                    onClick={(ev) => ev.preventDefault()}
                  >
                    <div className="coin-item coin-btc">
                      <div className="coin-icon">
                        <Icon name={icon} />
                      </div>
                      <div className="coin-info">
                        <span className="coin-name">{wallet}</span>
                        <span className="coin-text">1X38 * * * * YW94</span>
                      </div>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu dropdown-menu-auto dropdown-menu-mxh">
                    <div className="buysell-cc-list">
                      <DropdownItem className="buysell-cc-item selected" onClick={() => [setWallet("BTC Wallet"), setIcon("sign-btc-alt")]}>
                        <Link href="#" to="#" className="buysell-cc-opt" data-currency="btc">
                          <div className="coin-item coin-btc">
                            <div className="coin-icon">
                              <Icon name="sign-btc-alt" />
                            </div>
                            <div className="coin-info">
                              <span className="coin-name">BTC Wallet</span>
                              <span className="coin-text">1X38 * * * * YW94</span>
                            </div>
                          </div>
                        </Link>
                      </DropdownItem>
                      <DropdownItem className="buysell-cc-item" onClick={() => [setWallet("Ethereum (ETH)"), setIcon("sign-eth-alt")]}>
                        <Link href="#" to="#" className="buysell-cc-opt" data-currency="eth">
                          <div className="coin-item coin-eth">
                            <div className="coin-icon">
                              <Icon name="sign-eth-alt" />
                            </div>
                            <div className="coin-info">
                              <span className="coin-name">Ethereum (ETH)</span>
                              <span className="coin-text">Not order yet!</span>
                            </div>
                          </div>
                        </Link>
                      </DropdownItem>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Form> */}
            <div className="buysell-field form-action text-center">
              <div>
                <Button
                  color="primary"
                  className="btn btn-primary btn-lg"
                  onClick={onClickApproveOrder}
                >
                  Confirm the Order
                </Button>
              </div>
              <div className="pt-3">
                <a
                  href="#"
                  className="link link-danger"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onFormCancel();
                  }}
                >
                  Cancel Order
                </a>
              </div>
            </div>
          </Block>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modal.confirm}
        toggle={() => setModal({ confirm: false })}
        className="modal-dialog-centered"
        size="md"
      >
        <a
          href="#close"
          onClick={(ev) => {
            ev.preventDefault();
            onFormCancel();
          }}
          className="close"
        >
          <Icon name="cross-sm" />
        </a>
        <ModalBody className="modal-body-lg text-center">
          <div className="nk-modal">
            <Icon name="check" className="nk-modal-icon icon-circle icon-circle-xxl bg-success" />
            <h4 className="nk-modal-title">Order was placed successfully!</h4>
            <div className="nk-modal-text">
              {/* <p className="caption-text">
                You’ve successfully bought <strong>0.5968</strong> BTC for <strong>200.00</strong> USD.
              </p> */}
              {/* <p className="sub-text-sm">
                Learn when you reciveve bitcoin in your wallet.
                <Link to="#" tag="a">
                  Click here
                </Link>
              </p> */}
            </div>
            <div className="nk-modal-action-lg">
              <ul className="btn-group gx-4">
                <li onClick={() => setModal({ confirm: false })}>
                  <Link className="btn btn-lg btn-mw btn-primary">
                    Return
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </Card>
  );
};

export default BuySell;
