import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import { Card, Col, Row, Spinner } from "reactstrap";
import { getLinkToken, getPlaidItems, setAccessToken } from "../../api";
import { CREATE_PORTFOLIO, DELETE_PORTFOLIO, GET_USER_PORTFOLIOS } from "../../api/graphql";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle
} from "../../components/Component";
import SpinnerWrapper from "../../components/SpinnerWrapper";
import Wallet from "../../components/partials/panel/wallet/Wallet";
import useAuth from "../../hooks/useAuth";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import strings from "../../utils/Strings";
import SubscriptionLimit from "../../components/SubscriptionLimit";
import PortfolioFormModal from "./PortfolioFormModal";
import { tradingBotNames } from "../strategies/StrategyPolicyBuilder/RuleBuilder/constants";
import { ModalDanger } from "../components/Modals";

const Portfolios = () => {
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    color: "#aabbcc",
    default: false,
    initialFunds: 10000
  })
  const [linkToken, setLinkToken] = useState(null);
  const [loadingPortfolios, setLoadingPortfolios] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);

  const [createPortfolioMutationLink] = useMutation(CREATE_PORTFOLIO);
  const [deletePortfolioMutationLink] = useMutation(DELETE_PORTFOLIO);
  const generateToken = async () => {
    await getLinkToken({
      user,

    }, (data) => {
      setLinkToken(data.link_token);
    }, (err) => {
      console.error(err);
    })
  };
  useEffect(() => {
    generateToken();
  }, []);
  const onSuccess = React.useCallback((public_token, metadata) => {
    // send public_token to server
    setAccessToken({ public_token, userUID: user.uid }, (data) => {
      console.log(data);
    }, (err) => {
      console.error(err);
    })
    // Handle response ...
  }, []);

  const config = {
    token: linkToken,
    onSuccess,
  };
  const { open, ready } = usePlaidLink(config);
  const { data, loading, errors } = useQuery(GET_USER_PORTFOLIOS, {
    variables: {
      filter: {
        userUID: user?.uid,
        type: "paper"
      }
    }
  });

  const onFormSubmit = async (data) => {
    try {
      setIsLoading(true);
      const portfolioName = data?.name === "" ? tradingBotNames[Math.floor(Math.random() * tradingBotNames.length)] : data.name;
      let submittedData = {
        name: portfolioName,
        color: data.color,
        userUID: user.uid,
        description: data.description,
        default: data.default,
        initialFunds: data?.initialFunds || 10000,
        funds: data?.initialFunds || 10000,
        icon: "briefcase",
        type: "paper"
      };

      const res = await createPortfolioMutationLink({
        variables: { record: submittedData },
        refetchQueries: [
          {
            query: GET_USER_PORTFOLIOS,
            variables: {
              filter: {
                userUID: user.uid,
                type: "paper"
              }
            }
          }
        ]
      },
      );
      setIsLoading(false);
      setModal({
        add: false
      })
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setModal({
        add: false
      })
    }
  };

  const onClickDelete = (id) => {
    const title = "YO!";
    const desc = "Are you sure you want to delete this portfolio?";

    setDeleteModalData({
      title,
      description: desc,
      approveButtonText: "Delete",
      approveButtonColor: "danger",
      cancelButtonText: "Back",
      idToDelete: id,
      onClickDelete: async (id) => {
        await onApproveDelete(id)
      }
    });
    toggleDelete();
  }

  const onApproveDelete = async (id) => {
    const res = await deletePortfolioMutationLink({
      variables: { id },
      refetchQueries: [
        {
          query: GET_USER_PORTFOLIOS,
          variables: {
            filter: {
              userUID: user.uid,
              type: "paper"
            }
          }
        }
      ]
    },
    );
  }

  const closeModal = () => {
    setModal({ add: false })
  };

  if (loading) return <SpinnerWrapper />
  return (
    <React.Fragment>
      <Head title="Portfolios"></Head>
      <Content>
        <SubscriptionLimit filter={user.uid} hideAddButton={false} type={"portfolio"} current={data?.portfolioMany?.length} setModal={() => setModal({ add: true })} />
        <Block>
          <Row className="g-gs">
            {data?.portfolioMany?.map(p => {
              return (
                <Col sm="6" lg="4">
                  <Card className="card-bordered">
                    <Wallet
                      onDeletePortfolio={onClickDelete}
                      className={p?.default ? "is-default" : ""}
                      icon={p?.icon}
                      title={p?.name}
                      firstAmount={strings.price(p?.portfolioStats?.nlv || p?.initialFunds)}
                      firstCurrency=""
                      secondAmount={p?.portfolioStats?.portfolioChange}
                      secondCurrency=""
                      id={p?._id}
                    />
                  </Card>
                </Col>
              )
            })}
          </Row>
        </Block>
        {/* <Block size="lg">
          <BlockHead size="sm">
            <BlockHeadContent>
              <BlockTitle tag="h5">Live portfolios</BlockTitle>
            </BlockHeadContent>
          </BlockHead>
          {loadingPortfolios ? <Spinner /> : <Row className="g-gs">
            {plaidItems?.map(p => {
              return (
                <Col sm="6" lg="4">
                  <Card className="card-bordered is-dark">
                    <Wallet
                      className={p?.default ? "is-default" : ""}
                      icon={"briefcase"}
                      title={p?.institution?.name + "/" + p?.balance?.accounts?.[0]?.official_name}
                      firstAmount={strings.price(p?.balance?.accounts?.[0]?.balances?.current)}
                      firstCurrency=""
                      secondAmount={p?.portfolioStats?.portfolioChange}
                      secondCurrency=""
                      id={p?._id}
                    />
                  </Card>
                </Col>
              )
            })}
            <Col sm="6" lg="4">
              <Card className="card-bordered dashed h-100" onClick={open}>
                <div className="nk-wgw-add">
                  <div className="nk-wgw-inner">
                    <a>
                      <div className="add-icon">
                        <em className="icon ni ni-plus"></em>
                      </div>
                      <h6 className="title">Connect portfolio</h6>
                    </a>
                    <span className="sub-text">Connect your portfolio and traid like a ninja!</span>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>}
        </Block> */}
      </Content>
      <PortfolioFormModal isLoading={isLoading} modal={modal?.add} modalType="add" formData={formData} setFormData={setFormData} closeModal={closeModal} onSubmit={onFormSubmit} />
      {deleteModalData && <ModalDanger modalError={modalDelete} toggleError={toggleDelete} modalData={deleteModalData} />}
    </React.Fragment>
  );
};

export default Portfolios;
