import React, { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Badge, Card, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PreviewAltCard,
} from "../../components/Component";
import { Link, useLocation, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_PORTFOLIO_BY_ID } from "../../api/graphql";
import SpinnerWrapper from "../../components/SpinnerWrapper";
import PortfolioPage from "./PortfolioPage";

const PortfolioContainer = () => {
  const { pathname } = useLocation();
  const portfolioId = pathname.split('/')[2];
  const { data: portfolioData, refetch, fetchMore, loading, error } = useQuery(GET_PORTFOLIO_BY_ID, {
    variables: {
      id: portfolioId
    }
  });
  if (loading) return <SpinnerWrapper />

  return (
    <PortfolioPage portfolioData={portfolioData?.portfolioById} />
  );
};
export default PortfolioContainer;
