import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { Badge, Button, Modal, ModalBody } from "reactstrap";
import {
    Block,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    Col,
    Icon,
    RSelect,
    Row
} from "../../../components/Component";
import Head from "../../../layout/head/Head";
import strings from "../../../utils/Strings";
import { getDateStructured } from "../../../utils/Utils";
import ScreenDataTable from '../../screener/ScreenDataTable';

const PositionDefault = ({ portfolioId, quotes, positionsData, allMessages }) => {
    const [data, setData] = useState(positionsData);
    const [smOption, setSmOption] = useState(false);

    const [formData, setFormData] = useState({
        id: null,
        orderId: "",
        date: new Date(),
        status: "Delivered",
        customer: "",
        purchased: "",
        total: "",
        check: false,
    });
    const [view, setView] = useState({
        add: false,
        details: false,
    });

    // resets forms
    const resetForm = () => {
        setFormData({
            id: null,
            orderId: "",
            date: new Date(),
            status: "Delivered",
            customer: "",
            purchased: "",
            total: "",
            check: false,
        });
    };

    const onFormSubmit = (form) => {
        const { customer, purchased, total } = form;
        let submittedData = {
            id: data.length + 1,
            orderId: "95981",
            date: getDateStructured(formData.date),
            status: formData.status,
            customer: customer,
            purchased: purchased,
            total: total,
            check: false,
        };
        setData([submittedData, ...data]);
        setView({ add: false, details: false });
        resetForm();
    };

    useEffect(() => {
        reset(formData)
    }, [formData]);

    useEffect(() => {
        setData(positionsData)
    }, [positionsData]);

    // function to close the form modal
    const onFormCancel = () => {
        setView({ add: false, details: false });
        resetForm();
    };

    const portfolioPositionColumns = [
        {
            name: "Asset",
            selector: (row) => row.asset,
            sortable: false,
            cell: (row) => <span>{row.asset} </span>,
            // hide: "lg",
        },
        {
            name: "Status",
            selector: (row) => row.status,
            sortable: false,
            cell: (row) => <Badge>{row.status} </Badge>,
            // hide: "lg",
        },
        {
            name: "Last",
            selector: (row) => row.asset,
            sortable: true,
            cell: (row) => {
                const q = Array.isArray(quotes?.quotes?.quote) ? quotes?.quotes?.quote?.filter(q => q.symbol === row.asset)[0] : quotes?.quotes?.quote
                const last = parseFloat(allMessages?.[row?.asset]?.trade?.last) || q?.last;
                return (
                    <span>{strings.priceDecimal(last)} </span>
                )
            },
            // hide: "lg",
        },
        {
            name: "Change",
            selector: (row) => row.asset,
            sortable: true,
            cell: (row) => {
                const q = Array.isArray(quotes?.quotes?.quote) ? quotes?.quotes?.quote?.filter(q => q.symbol === row.asset)[0] : quotes?.quotes?.quote
                const last = parseFloat(allMessages?.[row?.asset]?.trade?.last) || q?.last;
                const prevClose = q?.prevclose;
                const change = last - prevClose
                const changePct = (change / prevClose) * 100
                return (
                    <span className={changePct > 0 ? "text-success" : changePct < 0 ? "text-danger" : ""}>{strings.priceDecimal(change)} ({strings.percentSignFormatter(changePct)}) </span>
                )
            },
            // hide: "lg",
        },
        {
            name: "Daily P&L",
            selector: (row) => ({ asset: row.asset, orders: row.orders, crearedAt: row.crearedAt }),
            sortable: true,
            cell: (row) => {
                const q = Array.isArray(quotes?.quotes?.quote) ? quotes?.quotes?.quote?.filter(q => q.symbol === row.asset)[0] : quotes?.quotes?.quote
                const last = parseFloat(allMessages?.[row?.asset]?.trade?.last) || q?.last;
                const prevClose = q?.prevclose;
                const todayPurchase = row?.orders.filter(o => {
                    // Get today's date
                    const d = new Date(o.orderDate)
                    const today = new Date();
                    // call setHours to take the time out of the comparison
                    return d.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0)
                });
                const excludeTodayPurchase = row?.orders.filter(o => {
                    // Get today's date
                    const d = new Date(o.orderDate)
                    const today = new Date();
                    // call setHours to take the time out of the comparison
                    return d.setHours(0, 0, 0, 0) != today.setHours(0, 0, 0, 0)
                });
                const totalShares = row?.orders.reduce((acc, d) => acc + d.sizeValue, 0);
                const todayTotalShares = todayPurchase.reduce((acc, d) => acc + d.sizeValue, 0);
                const excludeTodayTotalShares = excludeTodayPurchase.reduce((acc, d) => acc + d.sizeValue, 0);

                const todayTotalPrice = todayPurchase.reduce((acc, d) => acc + (d.price * d.sizeValue), 0);
                const todayAvgPrice = todayTotalPrice / todayTotalShares || 0;

                const newPositionsPNL = ((last - todayAvgPrice) * todayTotalShares);
                const dailyPNL = ((last - prevClose) * excludeTodayTotalShares) + newPositionsPNL
                return (
                    <span className={dailyPNL > 0 ? "text-success" : dailyPNL < 0 ? "text-danger" : ""}>{strings.priceDecimal(dailyPNL)}</span>
                )
            },
        },
        {
            name: "Avg. price",
            selector: (row) => row.avgPrice,
            sortable: true,
            cell: (row) => <span>{strings.priceDecimal(row.avgPrice)}</span>,
            // hide: "lg",
        },
        {
            name: "Shares",
            selector: (row) => row.size,
            sortable: true,
            cell: (row) => <span>{row.size}</span>,
            // hide: "lg",
        },
        {
            name: "Cost basis",
            selector: (row) => row.costBasis,
            sortable: true,
            cell: (row) => <span>{strings.priceDecimal(row.costBasis)}</span>,
            // hide: "lg",
        },
        {
            name: "Mkt. value",
            selector: (row) => ({ asset: row.asset, size: row.size }),
            sortable: true,
            cell: (row) => {
                const q = Array.isArray(quotes?.quotes?.quote) ? quotes?.quotes?.quote?.filter(q => q.symbol === row.asset)[0] : quotes?.quotes?.quote
                const last = parseFloat(allMessages?.[row?.asset]?.trade?.last) || q?.last;
                return (
                    <span>{strings.priceDecimal(last * row.size)} </span>
                )
            },
        },
        {
            name: "Profit",
            selector: (row) => ({ profit: row.profit, asset: row.asset, size: row.size, costBasis: row.costBasis }),
            sortable: true,
            cell: (row) => {
                const q = Array.isArray(quotes?.quotes?.quote) ? quotes?.quotes?.quote?.filter(q => q.symbol === row.asset)[0] : quotes?.quotes?.quote
                const last = parseFloat(allMessages?.[row?.asset]?.trade?.last) || q?.last;

                const t = row.profit ? row.profit : ((last * row.size) - row.costBasis)
                return (
                    <span className={t > 0 ? "text-success" : t < 0 ? "text-danger" : ""}>{strings.priceDecimal(t)} </span>
                )
            },
            // hide: "lg",
        },
    ];

    const { reset, register, handleSubmit, formState: { errors } } = useForm();

    return (
        <React.Fragment>
            <BlockHead size="sm">
                <BlockBetween>
                    <BlockHeadContent>
                        <h5>Positions</h5>
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead>

            <Block>
                <ScreenDataTable fileName={`${portfolioId}_positions`} searchField={"asset"} data={data} columns={portfolioPositionColumns} pagination actions />
            </Block>

            <Modal isOpen={view.add} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <a href="#cancel" className="close">
                        {" "}
                        <Icon
                            name="cross-sm"
                            onClick={(ev) => {
                                ev.preventDefault();
                                onFormCancel();
                            }}
                        ></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Add Order</h5>
                        <div className="mt-4">
                            <form onSubmit={handleSubmit(onFormSubmit)}>
                                <Row className="g-3">
                                    <Col md="12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="customer">
                                                Customer Name
                                            </label>
                                            <div className="form-control-wrap">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    {...register('customer', {
                                                        required: "This field is required",
                                                    })}
                                                    onChange={(e) => setFormData({ ...formData, customer: e.target.value })}
                                                    value={formData.customer} />
                                                {errors.customer && <span className="invalid">{errors.customer.message}</span>}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="date">
                                                Date of order
                                            </label>
                                            <div className="form-control-wrap">
                                                <DatePicker
                                                    selected={formData.date}
                                                    className="form-control"
                                                    onChange={(date) => setFormData({ ...formData, date: date })}
                                                />
                                                {errors.date && <span className="invalid">{errors.date.message}</span>}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="purchased">
                                                Purchased Product
                                            </label>
                                            <div className="form-control-wrap">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    {...register('purchased', { required: "This is required" })}
                                                    value={formData.purchased}
                                                    onChange={(e) => setFormData({ ...formData, purchased: e.target.value })} />
                                                {errors.purchased && <span className="invalid">{errors.purchased.message}</span>}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="total">
                                                Total Price
                                            </label>
                                            <div className="form-control-wrap">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    {...register('total', { required: "This is required" })}
                                                    value={formData.total}
                                                    onChange={(e) => setFormData({ ...formData, total: e.target.value })} />
                                                {errors.total && <span className="invalid">{errors.total.message}</span>}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="status">
                                                Status
                                            </label>
                                            <div className="form-control-wrap">
                                                <RSelect
                                                    name="status"
                                                    options={[
                                                        { value: "On Hold", label: "On Hold" },
                                                        { value: "Delivered", label: "Delivered" },
                                                    ]}
                                                    onChange={(e) => setFormData({ ...formData, status: e.value })}
                                                    value={{ value: formData.status, label: formData.status }}
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col size="12">
                                        <Button color="primary" type="submit">
                                            <Icon className="plus"></Icon>
                                            <span>Add Order</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={view.details} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <a href="#cancel" className="close">
                        {" "}
                        <Icon
                            name="cross-sm"
                            onClick={(ev) => {
                                ev.preventDefault();
                                onFormCancel();
                            }}
                        ></Icon>
                    </a>
                    <div className="nk-tnx-details mt-sm-3">
                        <div className="nk-modal-head mb-3">
                            <h5 className="title">Order Details</h5>
                        </div>
                        <Row className="gy-3">
                            <Col lg={6}>
                                <span className="sub-text">Order Id</span>
                                <span className="caption-text">{formData.orderId}</span>
                            </Col>
                            <Col lg={6}>
                                <span className="sub-text">Status</span>
                                <span
                                    className={`dot bg-${formData.status === "Delivered" ? "success" : "warning"} d-sm-none`}
                                ></span>
                                <Badge
                                    className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
                                    color={
                                        formData.status === "Delivered" ? "success" : "warning"
                                    }
                                >
                                    {formData.status}
                                </Badge>
                            </Col>
                            <Col lg={6}>
                                <span className="sub-text">Customer</span>
                                <span className="caption-text">{formData.customer}</span>
                            </Col>
                            <Col lg={6}>
                                <span className="sub-text">Purchased Product</span>
                                <span className="caption-text">{formData.purchased}</span>
                            </Col>
                            <Col lg={6}>
                                <span className="sub-text">Total Price</span>
                                <span className="caption-text">{formData.total}</span>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default PositionDefault;
