import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fontawesome from '@fortawesome/fontawesome'
import { faCompass, faChess, faUserNinja, faGlasses, faUsersViewfinder, faBinoculars, faRobot } from "@fortawesome/free-solid-svg-icons";
fontawesome.library.add(faCompass, faChess, faUserNinja, faGlasses, faUsersViewfinder, faBinoculars, faRobot);

const menu = [
  {
    icon: "home",
    text: "home",
    link: "/home",
  },
  {
    icon: <span className="nk-menu-icon" style={{
      paddingRight: 12,
      fontSize: 24
    }} ><FontAwesomeIcon icon="robot" /></span>,
    text: "your bots",
    link: "/bots",
  },
  {
    icon: <span className="nk-menu-icon" style={{
      paddingRight: 12,
      fontSize: 24
    }} ><FontAwesomeIcon icon="compass" /></span>,
    text: "explore",
    link: "/explore",
  },
  {
    icon: "activity",
    text: "market",
    link: "/market",
  },
  // {
  //   icon: "reports",
  //   text: "news",
  //   link: "/news",
  // },
  {
    icon: "briefcase",
    text: "portfolios",
    link: "/portfolios",
  },
  // {
  //   icon: <span className="nk-menu-icon" style={{
  //     paddingRight: 12,
  //     fontSize: 24
  //   }} ><FontAwesomeIcon icon="chess" /></span>,
  //   text: "strategies",
  //   link: "/strategies",
  // },
  {
    icon: <span className="nk-menu-icon" style={{
      paddingRight: 12,
      fontSize: 24
    }} ><FontAwesomeIcon icon="glasses" /></span>,
    text: "watchlists",
    link: "/watchlists",
  },
  {
    icon: <span className="nk-menu-icon" style={{
      paddingRight: 12,
      fontSize: 24
    }} ><FontAwesomeIcon icon="binoculars" /></span>,
    text: "screener",
    link: "/screener",
  },
  // {
  //   text: "Traid hub",
  //   link: "/traid-hub",
  //   badge: "new",
  //   icon: "puzzle-fill"
  // },
  // {
  //   icon: <span className="nk-menu-icon" style={{
  //     paddingRight: 12,
  //     fontSize: 24
  //   }} ><FontAwesomeIcon icon="users-viewfinder" /></span>,
  //   text: "social analysis",
  //   link: "/social-analysis",
  //   disabled: true
  // },
  {
    icon: "book-read",
    text: "AI blog",
    link: "/blog",
    disabled: true
  },
  // {
  //   icon: "book-read",
  //   text: "Educational",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Technical analysis",
  //       link: "/education/technical-analysis",
  //     },
  //     {
  //       text: "Support and resistance",
  //       link: "/education/support-and-resistance",
  //     },
  //     {
  //       text: "Charts basics",
  //       link: "/education/carts-basics",
  //     },
  //     {
  //       text: "Time frames",
  //       link: "/education/time-frames",
  //     },
  //     {
  //       text: "Trends",
  //       link: "/education/trends",
  //     },
  //     {
  //       text: "Dow theory",
  //       link: "/education/dow-theory",
  //     },
  //     {
  //       text: "Warren Buffett indicator",
  //       link: "/education/warren-buffett-indicator",
  //     },
  //   ],
  // },
  {
    icon: <span className="nk-menu-icon" style={{
      paddingRight: 12,
      fontSize: 24
    }} ><FontAwesomeIcon icon="user-ninja" /></span>,
    text: "AI ninja",
    link: "/ninja-chat",
    badge: "new",
  },
  // {
  //   icon: "bitcoin-cash",
  //   text: "Crypto Dashboard",
  //   link: "/crypto",
  // },
  // {
  //   icon: "growth",
  //   text: "Analytics Dashboard",
  //   link: "/analytics",
  // },
  // {
  //   icon: "coins",
  //   text: "Invest Dashboard",
  //   link: "/invest",
  // },
  // {
  //   heading: "Pre-built Pages",
  // },
  // {
  //   icon: "tile-thumb",
  //   text: "Projects",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Project Cards",
  //       link: "/project-card",
  //     },
  //     {
  //       text: "Project List",
  //       link: "/project-list",
  //     },
  //   ],
  // },
  // {
  //   icon: "users",
  //   text: "User Manage",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "User List - Regular",
  //       link: "/user-list-regular",
  //     },
  //     {
  //       text: "User List - Compact",
  //       link: "/user-list-compact",
  //     },
  //     {
  //       text: "User Details - Regular",
  //       link: "/profile/1",
  //     },
  //     {
  //       text: "User Profile - Regular",
  //       link: "/user-profile-regular",
  //     },
  //     {
  //       text: "User Contact - Card",
  //       link: "/user-contact-card",
  //     },
  //   ],
  // },
  // {
  //   icon: "file-docs",
  //   text: "AML / KYCs",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "KYC List - Regular",
  //       link: "/kyc-list-regular",
  //     },
  //     {
  //       text: "KYC Details - Regular",
  //       link: "/kyc-details-regular/UD01544",
  //     },
  //   ],
  // },
  // {
  //   icon: "tranx",
  //   text: "Transaction",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Trans List - Basic",
  //       link: "/transaction-basic",
  //     },
  //     {
  //       text: "Trans List - Crypto",
  //       link: "/transaction-crypto",
  //     },
  //   ],
  // },
  // {
  //   icon: "grid-alt",
  //   text: "Applications",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Messages",
  //       link: "/app-messages",
  //     },
  //     {
  //       text: "Inbox / Mail",
  //       link: "/app-inbox",
  //     },
  //     {
  //       text: "Calendar",
  //       link: "/app-calender",
  //     },
  //     {
  //       text: "File Manager",
  //       link: "/app-file-manager",
  //       badge: "new",
  //     },
  //     {
  //       text: "Kanban Board",
  //       link: "/app-kanban",
  //     },
  //   ],
  // },
  // {
  //   icon: "card-view",
  //   text: "Products",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Product List",
  //       link: "/product-list",
  //     },
  //     {
  //       text: "Product Card",
  //       link: "/product-card",
  //     },
  //     {
  //       text: "Product Details",
  //       link: "/product-details/0",
  //     },
  //   ],
  // },
  // {
  //   icon: "file-docs",
  //   text: "Invoice",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Invoice List",
  //       link: "/invoice-list",
  //     },
  //     {
  //       text: "Invoice Details",
  //       link: "/invoice-details/1",
  //     },
  //   ],
  // },
  // {
  //   icon: "view-col",
  //   text: "Pricing Table",
  //   link: "/pricing-table",
  // },
  // {
  //   icon: "img",
  //   text: "Image Gallery",
  //   link: "/image-gallery",
  // },
  // {
  //   heading: "Misc Pages",
  // },
  // {
  //   icon: "signin",
  //   text: "Auth Pages",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Login / Signin",
  //       link: "/login",
  //       newTab: true,
  //     },
  //     {
  //       text: "Register / Signup",
  //       link: "/auth-register",
  //       newTab: true,
  //     },
  //     {
  //       text: "Forgot Password",
  //       link: "/auth-reset",
  //       newTab: true,
  //     },
  //     {
  //       text: "Success / Confirm",
  //       link: "/auth-success",
  //       newTab: true,
  //     },
  //   ],
  // },
  // {
  //   icon: "files",
  //   text: "Error Pages",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "404 Classic",
  //       link: "/errors/404-classic",
  //       newTab: true,
  //     },
  //     {
  //       text: "504 Classic",
  //       link: "/errors/504-classic",
  //       newTab: true,
  //     },
  //     {
  //       text: "404 Modern",
  //       link: "/errors/404-modern",
  //       newTab: true,
  //     },
  //     {
  //       text: "504 Modern",
  //       link: "/errors/504-modern",
  //       newTab: true,
  //     },
  //   ],
  // },
  // {
  //   heading: "Components",
  // },
  // {
  //   icon: "layers",
  //   text: "Ui Elements",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Alerts",
  //       link: "/components/alerts",
  //     },
  //     {
  //       text: "Accordions",
  //       link: "/components/accordions",
  //     },
  //     {
  //       text: "Avatar",
  //       link: "/components/avatar",
  //     },
  //     {
  //       text: "Badges",
  //       link: "/components/badges",
  //     },
  //     {
  //       text: "Buttons",
  //       link: "/components/buttons",
  //     },
  //     {
  //       text: "Button Group",
  //       link: "/components/button-group",
  //     },
  //     {
  //       text: "Breadcrumbs",
  //       link: "/components/breadcrumbs",
  //     },
  //     {
  //       text: "Cards",
  //       link: "/components/cards",
  //     },
  //     {
  //       text: "Carousel",
  //       link: "/components/carousel",
  //     },
  //     {
  //       text: "Dropdowns",
  //       link: "/components/dropdowns",
  //     },
  //     {
  //       text: "Modals",
  //       link: "/components/modals",
  //     },
  //     {
  //       text: "Pagination",
  //       link: "/components/pagination",
  //     },
  //     {
  //       text: "Popovers",
  //       link: "/components/popovers",
  //     },
  //     {
  //       text: "Progress",
  //       link: "/components/progress",
  //     },
  //     {
  //       text: "Spinner",
  //       link: "/components/spinner",
  //     },
  //     {
  //       text: "Tabs",
  //       link: "/components/tabs",
  //     },
  //     {
  //       text: "Toast",
  //       link: "/components/toast",
  //     },
  //     {
  //       text: "Typography",
  //       link: "/components/typography",
  //     },
  //     {
  //       text: "Tooltips",
  //       link: "/components/tooltips",
  //     },
  //     {
  //       text: "Utilities",
  //       active: false,
  //       subMenu: [
  //         {
  //           text: "Borders",
  //           link: "/components/util-border",
  //         },
  //         {
  //           text: "Colors",
  //           link: "/components/util-colors",
  //         },
  //         {
  //           text: "Display",
  //           link: "/components/util-display",
  //         },
  //         {
  //           text: "Embeded",
  //           link: "/components/util-embeded",
  //         },
  //         {
  //           text: "Flex",
  //           link: "/components/util-flex",
  //         },
  //         {
  //           text: "Text",
  //           link: "/components/util-text",
  //         },
  //         {
  //           text: "Sizing",
  //           link: "/components/util-sizing",
  //         },
  //         {
  //           text: "Spacing",
  //           link: "/components/util-spacing",
  //         },
  //         {
  //           text: "Others",
  //           link: "/components/util-others",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   icon: "dot-box",
  //   text: "Crafted Icons",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "SVG Icon-Exclusive",
  //       link: "/svg-icons",
  //     },
  //     {
  //       text: "Nioicon - HandCrafted",
  //       link: "/nioicon",
  //     },
  //   ],
  // },
  // {
  //   icon: "table-view",
  //   text: "Tables",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Basic Tables",
  //       link: "/table-basic",
  //     },
  //     {
  //       text: "Special Tables",
  //       link: "/table-special",
  //     },
  //     {
  //       text: "DataTables",
  //       link: "/table-datatable",
  //     },
  //   ],
  // },
  // {
  //   icon: "card-view",
  //   text: "Forms",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Form Elements",
  //       link: "/components/form-elements",
  //     },
  //     {
  //       text: "Checkbox Radio",
  //       link: "/components/checkbox-radio",
  //     },
  //     {
  //       text: "Advanced Controls",
  //       link: "/components/advanced-control",
  //     },
  //     {
  //       text: "Input Group",
  //       link: "/components/input-group",
  //     },
  //     {
  //       text: "Form Upload",
  //       link: "/components/form-upload",
  //     },
  //     {
  //       text: "Date Time Picker",
  //       link: "/components/datetime-picker",
  //     },
  //     {
  //       text: "Number Spinner",
  //       link: "/components/number-spinner",
  //     },
  //     {
  //       text: "noUiSlider",
  //       link: "/components/nouislider",
  //     },
  //     {
  //       text: "Form Layouts",
  //       link: "/components/form-layouts",
  //     },
  //     {
  //       text: "Form Validation",
  //       link: "/components/form-validation",
  //     },
  //     {
  //       text: "Wizard Basic",
  //       link: "/components/wizard-basic",
  //     },
  //     {
  //       text: "Rich Editor",
  //       active: false,
  //       subMenu: [
  //         {
  //           text: "Quill",
  //           link: "/components/quill",
  //         },
  //         {
  //           text: "Tinymce",
  //           link: "/components/tinymce",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   icon: "pie",
  //   text: "Charts",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Chart Js",
  //       link: "/charts/chartjs",
  //     },
  //     {
  //       text: "Knobs",
  //       link: "/charts/knobs",
  //     },
  //   ],
  // },
  // {
  //   icon: "puzzle",
  //   text: "Widgets",
  //   subMenu: [
  //     {
  //       text: "Card Widgets",
  //       link: "/components/widgets/cards",
  //     },
  //     {
  //       text: "Chart Widgets",
  //       link: "/components/widgets/charts",
  //     },
  //     {
  //       text: "Rating Widgets",
  //       link: "/components/widgets/rating",
  //     },
  //   ],
  // },
  // {
  //   icon: "block-over",
  //   text: "Miscellaneous",
  //   subMenu: [
  //     {
  //       text: "Slick Sliders",
  //       link: "/components/misc/slick-slider",
  //     },
  //     {
  //       text: "JsTree",
  //       link: "/components/misc/jsTree",
  //     },
  //     {
  //       text: "React Toastify",
  //       link: "/components/misc/toastify",
  //     },
  //     {
  //       text: "Sweet Alert",
  //       link: "/components/misc/sweet-alert",
  //     },
  //     {
  //       text: "React DualListBox",
  //       link: "/components/misc/dual-list",
  //     },
  //     {
  //       text: "React Beautiful Dnd",
  //       link: "/components/misc/beautiful-dnd",
  //     },
  //     {
  //       text: "Google Map",
  //       link: "/components/misc/map",
  //     },
  //   ],
  // },
  // {
  //   icon: "text-rich",
  //   text: "Email Template",
  //   link: "/email-template",
  //   active: "false",
  // },
];
export default menu;

export { faChess };