import { gql } from '@apollo/client';

const GET_COMPANY_FILES = gql`
  query FileMany($filter: FilterFindManyFileInput) {
  fileMany(filter: $filter) {
            cik
            companyName
            fileType
            date
            txtLink
            htmlLink
            symbol
            q
        }
    }`

const GET_FEED_BY_SYMBOL = gql`
  query FeedPostMany($filter: FilterFindManyFeedPostInput, $sort: SortFindManyFeedPostInput) {
  feedPostMany(filter: $filter, sort: $sort) {
            title
            description
            link
            channel
            category
            pub_date
            link_title
            link_text
            sentiment_score
            nasdaq_tickers
            cs_tickers
            creator
        }
    }`

const GET_REDDIT_BY_SYMBOL = gql`
  query RedditSubmissionMany($filter: FilterFindManyRedditSubmissionInput, $sort: SortFindManyRedditSubmissionInput) {
  redditSubmissionMany(filter: $filter, sort: $sort) {
        submission_id
        author_name
        created_utc
        is_original_content
        is_self
        link_flair_text
        locked
        name
        num_comments
        over_18
        permalink
        score
        selftext
        spoiler
        stickied
        subreddit
        title
        upvote_ratio
        url
        tickers
        sentiment_score
        sort
        comments {
            submission_comment_id
            author_name
            body
            created_utc
            distinguished
            is_submitter
            link_id
            parent_id
            permalink
            saved
            score
            stickied
            submission_id
            subreddit
            subreddit_id
            sentiment_score
            tickers
        }
        }
    }`

const GET_ALL_FEED_POSTS = gql`
    query FeedPostMany($limit: Int, $sort: SortFindManyFeedPostInput ) {
  feedPostMany(limit: $limit, sort: $sort) {
            title
            description
            link
            channel
            category
            pub_date
            link_title
            link_text
            sentiment_score
            nasdaq_tickers
            cs_tickers
            creator
        }
}`

const GET_USER_PROFILE = gql`
    query UserOne($filter: FilterFindOneUserInput) {
        userOne(filter: $filter) {
            uid
            displayName
            email
            level
            strategies {
            _id
                backtests{
                    _id
                }
            }
            subscription
    }
}`

const GET_USER_BOTS = gql`
    query UserOne($filter: FilterFindOneUserInput) {
        userOne(filter: $filter) {
            uid
            level
        bots {
          userUID
            _id
            tab
            createdAt
            symbols
            flowDescription
            interval {
              label
              value
            }
            name
            color
            description
            botStats
        }
    }
}`

const GET_USER_STRATEGIES = gql`
    query UserOne($filter: FilterFindOneUserInput) {
        userOne(filter: $filter) {
            uid
            level
        strategies {
            _id
            flow
            fileName
            flowImage{
                url
            }
            name
            description
            backtests{
                createdAt
                performance
                _id
            }
        }
    }
}`

const GET_PUBLIC_BOTS = gql`
    query BotsMany($filter: FilterFindManyBotInput) {
        botMany(filter: $filter) {
            _id
            numberOfCopies
            createdAt
            symbols
            flowDescription
            interval {
              label
              value
            }
            name
            color
            description
            botStats
            userUID
    }
}`

const GET_USER_PLAID_ITEMS = gql`
    query PlaidItemsMany($filter: FilterFindManyPlaidItemInput) {
        plaidItemMany(filter: $filter) {
          _id
          createdAt
          access_token
          item_id
    }
}`

const GET_USER_PORTFOLIOS = gql`
    query PortfolioMany($filter: FilterFindManyPortfolioInput) {
        portfolioMany(filter: $filter) {
          _id
          portfolioStats
          createdAt
            name
            icon
            default
            description
            funds
            initialFunds
            strategies{
              status
              _id
              lastRunAt
              deployedAt
              stoppedAt
              strategyId
              strategy{
                _id
                flow
                name
                description
              }
              status
              interval{
                value
                label
              }
            }
            positions {
              asset
              status
              costBasis
              avgPrice
              size
              orders{
                executor
                orderDate
                asset
                price
                sizeValue
                actionType
              }
            }
    }
}`

const GET_USER_CHATS = gql`
    query UserOne($filter: FilterFindOneUserInput) {
        userOne(filter: $filter) {
            uid
            _id
            level
            chats {
                name
                _id
                state
                convo{
                  _id
                  chat
                  me
                  date
                }
            }
    }
}`

const GET_USER_WATCHLISTS = gql`
    query UserOne($filter: FilterFindOneUserInput) {
        userOne(filter: $filter) {
        watchlists {
            name
            symbols
            _id
        }
    }
}`

const GET_STRATEGY_BY_ID = gql`
    query StrategyById($id: MongoID!) {
        strategyById(_id: $id) {
            _id
            flow
            fileName
            flowImage{
                url
            }
            name
            description
    }
}`

const GET_PORTFOLIO_BY_ID = gql`
    query PortfolioById($id: MongoID!) {
        portfolioById(_id: $id) {
          _id
          createdAt
            name
            description
            funds
            portfolioStats
            initialFunds
            strategies{
              status
              _id
              lastRunAt
              deployedAt
              stoppedAt
              strategyId
              strategy{
                _id
                flow
                name
                description
              }
              status
              interval{
                value
                label
              }
            }
            positions {
              asset
              status
              costBasis
              avgPrice
              size
              orders{
                executor
                orderDate
                asset
                price
                sizeValue
                actionType
              }
            }
    }
}`

const GET_BACKTEST_BY_ID = gql`
    query BacktestById($id: MongoID!) {
        backtestById(_id: $id) {
            name
            portfolioId
            portfolio{
              _id
              name
              type
            description
            funds
            initialFunds
            portfolioStats
            positions {
              asset
              status
              costBasis
              avgPrice
              size
              profit
              orders{
                reasons
                orderDate
                asset
                price
                sizeValue
                actionType
              }
            }
            }
            interval{
                label
                value
            }
            strategyId
                status
                performance
                createdAt
                description
                start
                end
                _id
                initialFunds
                baselineAsset {
                    type
                    value
                }
    }
}`

const GET_REDDIT_ANALYSIS = gql`
query RedditSubmissionMany($filter: FilterFindManyRedditSubmissionInput, $limit: Int) {
  redditSubmissionMany(filter: $filter, limit: $limit) {
    tickers
    comments {
      tickers
      body
      author_name
      sentiment_score
    }
    author_name
    created_utc
    num_comments
    sentiment_score
    title
    selftext
    subreddit
  }
}
`
const GET_BOT_INFO = gql`
    query BotById($id: MongoID!) {
        botById(_id: $id) {
            userUID
            _id
            private
            color
            flow
            tab
            createdAt
            fileName
            symbols
            flowDescription
            interval {
              label
              value
            }
            state
            name
            description
            backtests {
              backtestStats
              portfolio {
                _id
                name
                type
                description
                funds
                initialFunds
                portfolioStats
                positions {
                    asset
                    status
                    costBasis
                    avgPrice
                    size
                    profit
                    orders{
                      reasons
                      orderDate
                      asset
                      price
                      sizeValue
                      actionType
                    }
                  }
              }
              name
              mode
              createdAt
              status
              performance
              description
              start
              end
              _id
              initialFunds
              interval{
                label
                value
              }
              baselineAsset {
                type
                value
              }
            }
    }
}`
const GET_STRATEGY_BACKTESTS = gql`
    query StrategyById($id: MongoID!) {
        strategyById(_id: $id) {
            userUID
            _id
            flow
            createdAt
            fileName
            flowImage{
                url
            }
            name
            description
            backtests {
                name
                createdAt
                status
                performance
                description
                start
                end
                _id
                initialFunds
                interval{
                label
                value
            }
                baselineAsset {
                    type
                    value
                }
            }
    }
}`

const STRATEGY_MANY = gql`
query StrategyMany($filter: FilterFindManyStrategyInput) {
  strategyMany(filter: $filter) {
    _id
    userUID
    flow
    type
    fileName
    flowImage{
      url
    }
    name
    description
  }
}
`

const GET_USER_LIMIT_COUNTS = gql`
query Query($backtestFilter: FilterCountBacktestInput, $portfolioFilter: FilterCountPortfolioInput, $botFilter: FilterCountBotInput, $strategyFilter: FilterCountStrategyInput, $portfolioStrategyFilter: FilterCountPortfolioStrategyInput, $watchlistFilter: FilterCountWatchlistInput) {
  backtestCount(filter: $backtestFilter)
  strategyCount(filter: $strategyFilter)
  botCount(filter: $botFilter)
  watchlistCount(filter: $watchlistFilter)
  portfolioStrategyCount(filter: $portfolioStrategyFilter)
  portfolioCount(filter: $portfolioFilter)
}
`



// mutations

const CREATE_PORTFOLIO_STRATEGY = gql`
    mutation portfolioStrategyCreateOne($record: CreateOnePortfolioStrategyInput!) {
      portfolioStrategyCreateOne(record: $record) {
            record{
              _id
            }
}
    }
`

const CREATE_PORTFOLIO_ORDER = gql`
    mutation createPortfolioOrderAndUpdatePosition($input: CreateOnePortfolioOrderInput!) {
      createPortfolioOrderAndUpdatePosition(input: $input) {
              _id
  }
}
`

const CREATE_CHAT = gql`
    mutation chatCreateOne($record: CreateOneChatInput!) {
      chatCreateOne(record: $record) {
            record{
              name
              _id
            }
  }
}
`

const CREATE_MESSAGES = gql`
    mutation MessageCreateMany($records: [CreateManyMessageInput!]!) {
      messageCreateMany(records: $records) {
            records{
              chat
              date
              _id
            }
  }
}
`

const CREATE_STRATEGY = gql`
    mutation strategyCreateOne($record: CreateOneStrategyInput!) {
        strategyCreateOne(record: $record) {
            record{
    name
    _id
            }
  }
}
`

const CREATE_BOT = gql`
    mutation botCreateOne($record: CreateOneBotInput!) {
        botCreateOne(record: $record) {
            record{
    name
    _id
            }
  }
}
`

const CREATE_PORTFOLIO = gql`
    mutation portfolioCreateOne($record: CreateOnePortfolioInput!) {
        portfolioCreateOne(record: $record) {
            record{
    name
    _id
            }
  }
}
`

export const UPDATE_USER_CHAT = gql`
    mutation chatUpdateById($id: MongoID!, $record: UpdateByIdChatInput!) {
        chatUpdateById(_id: $id, record: $record){
            record{
    _id
            }
        }
}
`

const UPDATE_PORTFOLIO_STRATEGY = gql`
    mutation portfolioStrategyUpdateById($id: MongoID!, $record: UpdateByIdPortfolioStrategyInput!) {
        portfolioStrategyUpdateById(_id: $id, record: $record){
            record{
    _id
            }
        }
}
`

const UPDATE_BOT = gql`
    mutation botUpdateById($id: MongoID!, $record: UpdateByIdBotInput!) {
        botUpdateById(_id: $id, record: $record){
            record{
    name
    _id
            }
        }
}
`

const UPDATE_WATCHLIST = gql`
    mutation watchlistUpdateById($id: MongoID!, $record: UpdateByIdWatchlistInput!) {
        watchlistUpdateById(_id: $id, record: $record){
            record{
    name
    _id
            }
        }
}
`

const CREATE_WATCHLIST = gql`
    mutation watchlistCreateOne($record: CreateOneWatchlistInput!) {
  watchlistCreateOne(record: $record) {
    record{
    name
    _id
    symbols
    }
  }
}
`

const DELETE_PORTFOLIO = gql`
    mutation PortfolioRemoveById($id: MongoID!) {
  portfolioRemoveById(_id: $id) {
    error {
      message
    }
  }
}
`

const DELETE_CHAT = gql`
    mutation ChatRemoveById($id: MongoID!) {
  chatRemoveById(_id: $id) {
    error {
      message
    }
  }
}
`

const DELETE_WATCHLIST = gql`
    mutation WatchlistRemoveById($id: MongoID!) {
  watchlistRemoveById(_id: $id) {
    error {
      message
    }
  }
}
`

const DELETE_BOT = gql`
    mutation BotRemoveById($id: MongoID!) {
  botRemoveById(_id: $id) {
    error {
      message
    }
  }
}
`

const DELETE_STRATEGY = gql`
    mutation StrategyRemoveById($id: MongoID!) {
  strategyRemoveById(_id: $id) {
    error {
      message
    }
  }
}
`

const DELETE_BACKTEST = gql`
    mutation BacktestRemoveById($id: MongoID!) {
  backtestRemoveById(_id: $id) {
    error {
      message
    }
  }
}
`

const RUN_PORTFOLIO_STRATEGY = gql`
    mutation runPortfolioStrategy($input: RunPortfolioStrategyInput) {
        runPortfolioStrategy(input: $input)
}
`

const COPY_BOT = gql`
    mutation copyBot($input: CopyBotInput) {
        copyBot(input: $input) {
          _id  
          name
            
  }
}
`

const CREATE_BACKTEST = gql`
    mutation createAndRunBacktest($input: CreateAndRunBacktestInput) {
        createAndRunBacktest(input: $input) {
            
    name
            
  }
}
`

const JUST_RUN_BACKTEST = gql`
    mutation runBacktest($input: CreateAndRunBacktestInput) {
      runBacktest(input: $input){
            
            name
                    
          }
}
`

export {
  JUST_RUN_BACKTEST, GET_PUBLIC_BOTS,
  CREATE_BACKTEST, CREATE_CHAT,
  CREATE_MESSAGES, CREATE_PORTFOLIO_ORDER,
  CREATE_PORTFOLIO_STRATEGY, CREATE_STRATEGY, CREATE_BOT, CREATE_PORTFOLIO, COPY_BOT, CREATE_WATCHLIST, DELETE_BACKTEST, DELETE_CHAT, DELETE_PORTFOLIO, DELETE_STRATEGY, DELETE_BOT, DELETE_WATCHLIST, GET_ALL_FEED_POSTS, GET_BACKTEST_BY_ID, GET_COMPANY_FILES, GET_FEED_BY_SYMBOL, GET_PORTFOLIO_BY_ID, GET_REDDIT_ANALYSIS, GET_REDDIT_BY_SYMBOL, GET_BOT_INFO, GET_STRATEGY_BACKTESTS, GET_STRATEGY_BY_ID, GET_USER_CHATS, GET_USER_LIMIT_COUNTS, GET_USER_PLAID_ITEMS, GET_USER_PORTFOLIOS, GET_USER_PROFILE, GET_USER_STRATEGIES, GET_USER_BOTS, GET_USER_WATCHLISTS, RUN_PORTFOLIO_STRATEGY, STRATEGY_MANY, UPDATE_PORTFOLIO_STRATEGY, UPDATE_WATCHLIST, UPDATE_BOT,

};

