import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import {
  Icon,
  Button,
  Col,
  RSelect,
} from "../../components/Component";
import {
  Modal,
  ModalBody,
  Form,
  Spinner,
  Badge,
} from "reactstrap";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import CustomColorPicker from "../../components/CustomColorPicker";
import { addCommas, removeNonNumeric } from "../../utils/Utils";

export const assetData = [
  { value: "AAPL", label: "AAPL" },
  { value: "MSFT", label: "MSFT" },
  { value: "TSLA", label: "TSLA" },
  { value: "SPY", label: "SPY" },
];

const schema = yup
  .object({
  })
  .required()

const PortfolioFormModal = ({ isLoading, modal, closeModal, onSubmit, formData, setFormData, modalType }) => {
  const { control, reset, register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    reset(formData)
  }, [formData]);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'symbols',
    rules: {
      required: true
    }
  })

  return (
    <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="xl">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">
            {modalType === "add" && "Let's add a new portfolio 💼"}</h5>
          <div className="mt-4">
            <Form className="row gy-4" onSubmit={handleSubmit(onSubmit)}>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    {...register('name', { required: "This field is required" })}
                    value={formData.name}
                    placeholder="Your portfolio identifier"
                    onChange={(e) => {
                      e.preventDefault();
                      setFormData({ ...formData, name: e.target.value })
                    }}
                    className="form-control" />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </Col>
              <Col size="12">
                <div className="form-group">
                  <label className="form-label">Description</label>
                  <input
                    value={formData.description}
                    placeholder="Describe your portfolio"
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    className="form-control-lg form-control no-resize" />
                </div>
              </Col>
              <Col lg="4">
                <div className="form-group">
                  <label className="form-label">Initial funds</label>
                  <div className="form-control-wrap">
                    <div className="form-text-hint">
                      <span className="overline-title">Usd</span>
                    </div>
                    <input
                      {...register('initialFunds', { required: "This field is required" })}
                      value={addCommas(removeNonNumeric(formData.initialFunds))}
                      type="text"
                      placeholder="Set initial funds for the strategy"
                      onChange={(e) => {
                        console.log(e.target.value);
                        setFormData({ ...formData, initialFunds: e.target.value })
                      }}
                      className="form-control-lg form-control no-resize" />
                  </div>
                </div>
              </Col>
              <Col lg="4">
                <div className="form-group">
                  <label className="form-label">Color</label>
                  <div className="form-control-wrap">
                    <CustomColorPicker color={formData.color} setColor={color => setFormData({ ...formData, color })} />
                  </div>
                </div>
              </Col>
              <Col lg="4">
                <div className="form-group">
                  <div className="custom-control custom-control-sm custom-checkbox" >
                    <input
                      type="checkbox"
                      className="form-control custom-control-input"
                      id="default"
                      name="default"
                      checked={formData.default}
                      onChange={(e) => {
                        setFormData({ ...formData, default: e.target.checked })
                      }}
                    />
                    <label className="form-label custom-control-label" htmlFor="default">default</label>
                  </div>
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2" style={{
                  float: "right"
                }}>
                  <li>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </Button>
                  </li>
                  <li>
                    <Button color="primary" size="md" type="submit">
                      {isLoading && <Spinner size="sm" type="grow" />}
                      {modalType === "add" && "Create portfolio"} {modalType === "edit" && "Update bot"}
                    </Button>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody >
    </Modal >
  );
};
export default PortfolioFormModal;
